import React from 'react';

const TableHead = (props) => {
  const {list, intl, className} = props;
  
  let items = [];

  items = list.map((title, index) => 
    <th key={index}>{intl.formatMessage({ id: title})}</th>
  );

  return <thead className={className}><tr>{items}</tr></thead>
}

export default TableHead;
