import React from 'react';

import './cookies-button.less';

const CookiesButton = (props) => {
  const {children, onClick, className} = props;

  return <button className={`cookie-button ${className}`} onClick={onClick}><div>{children}</div></button>  
}

export default CookiesButton;
