import React from 'react';
import PropTypes from 'prop-types';

import './image.less';

const Image = (props) => {
  const {source, alt, width, height, children, className} = props;
  const [size, setSize] = React.useState({width: width, height: height});

  const onImageLoad = ({ target: img }) => {
    if(width=='auto' && height=='auto'){
      const { offsetWidth, offsetHeight } = img;
      setSize({width: offsetWidth, height: offsetHeight});
    }
  };

  return <div className="image-container">
    <img 
      className={className}
      onLoad={onImageLoad}
      alt={alt}
      src={process.env.WEB_URL+source}
      width={size.width}
      height={size.height}
      loading="lazy"
    />
    {children}
  </div>
}

Image.defaultProps = {
  height: 'auto',
  width: 'auto'
}
  
Image.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.any,
  width: PropTypes.any
};

export default Image;
