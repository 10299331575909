import * as actionTypes from './actionTypes';

export const loading = () => ({
  type: actionTypes.LOADING,
});

export const loaded = () => ({
  type: actionTypes.LOADED,
});

export const error = (error) => ({
  type: actionTypes.ERROR,
  error,
});

export const getTokenRecaptcha = (data) => ({
    type: actionTypes.GET_TOKEN_RECAPTCHA,
    tokenRecaptcha: data
});