import React, {useState, useEffect} from "react";
import { useIntl } from "react-intl";
import Column from "../../layouts/Column";
import RowAuxiliarLayout from "../../layouts/RowAuxiliarLayout";
import Title from "../../components/Texts/Title";
import CookiesButton from "../../components/Buttons/CookiesButton";
import Paragraph from "../../components/Texts/Paragraph";
import CookiesModalAccordeon from "./CookiesModalAccordeon";

import './cookies-modal.less';

const CookiesModal = (props) =>{

    const intl = useIntl();
    const {content, show, closeModal, cookiesConsented, setCookiesConsented, callManage} = props;

    const [showMore, setShowMore] = useState(false);

    const setTitle = (index) => {
        return "cookies.modal.table.title"+index;
    }

    return <React.Fragment>
        {show == true && (<RowAuxiliarLayout className="background-opacity-layout">
            <Column className="dialog-cookies-container"> 
                <Title depth={2}>{intl.formatMessage({ id: 'cookies.modal.title'})}</Title>
                <Paragraph>
                    {intl.formatMessage(!showMore ? { id: 'cookies.modal.text1'} : { id: 'cookies.modal.text1.full'})}
                    <CookiesButton more={true} className="showmore-cookies-button" onClick={() => {setShowMore(!showMore);}}> 
                        {intl.formatMessage(!showMore ? { id: 'cookies.modal.text.button.more'} : { id: 'cookies.modal.text.button.less'})}
                    </CookiesButton>
                </Paragraph>
                {content.map((cookiesList, index) => (
                    <CookiesModalAccordeon title={setTitle(index+1)} key={index} cookies={cookiesList} index={index} setCookiesConsented={setCookiesConsented} cookiesConsented={cookiesConsented}/>
                ))}
                <Column className="align-vertically-center-cookies-buttons">
                    <CookiesButton onClick={callManage} className="cookiesButton">{intl.formatMessage({ id: 'cookies.modal.button.text1'})}</CookiesButton>
                    <CookiesButton onClick={closeModal} className="cookiesButton2">{intl.formatMessage({ id: 'cookies.modal.button.text2'})}</CookiesButton>
                </Column>
            </Column>
        </RowAuxiliarLayout>)}
    </React.Fragment>
}

export default CookiesModal;