import React from 'react';
import PropTypes from 'prop-types';

import './paragraph.less';

const Paragraph = (props) => {
  
  const {padding, children, className} = props;

  return <p className={`paragraph ${className} ${padding=="none" ? 'nopadding' : ''}`}>{children}</p>
}
  
Paragraph.propTypes = {
  padding: PropTypes.string
};

export default Paragraph;
