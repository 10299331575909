import TagManager from 'react-gtm-module'

const COOKIE_ANALYTICS = "analyticsAccepted";

const tagManagerArgs = {
  gtmId: 'GTM-W8W97KB'
}

export const getCookieAnalytics = () => getCookie(COOKIE_ANALYTICS);

export const setCookie = (cname, cvalue, exdays) => {
    const date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=Strict;Secure';
};
  
export const getCookie = (cname) => {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
};

export const manageCookies = (cookies) => {
    if(cookies.analytics){
      TagManager.initialize(tagManagerArgs);
      //ReactGA.initialize('G-X9GMQSQPZX');
      //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    }
    setCookie("analyticsAccepted", cookies.analytics ? true : false, 30000);
    setCookie("cookiesAccepted", true, 30000);
}
