import React, { useState } from 'react';
import TableHead from '../../components/Table/TableHead';
import TableContainer from '../../components/Table/TableContainer';
import TableBody from '../../components/Table/TableBody';
import { useIntl } from 'react-intl';


const CookiesModalTable = (props) => {
    const intl = useIntl();
    const textId = ["cookies.modal.table.header1", "cookies.modal.table.header2", "cookies.modal.table.header3"];

    const {cookies} = props;

    return <TableContainer>
        <TableHead className="cookies-table-head" list={textId} intl={intl}/>
        <TableBody className="cookies-table-body" cookies={cookies} intl={intl}/>
    </TableContainer>
    
}

export default CookiesModalTable;