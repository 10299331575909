import React, {useState, useEffect} from "react";
import CookiesWindow from './CookiesWindow'
import { getCookie } from "../../../utils/cookiesUtils";
import { setCookie } from "../../../utils/cookiesUtils";
import cookies from '../../../modules/cookies';
import {useSelector} from 'react-redux';

const CookieManager = () => {
    const [display, setDisplay] = useState(false);

    const cookiesMandatory = useSelector(cookies.selectors.getMandatoryCookiesList);
    const cookiesAnalytics = useSelector(cookies.selectors.getAnalyticsCookiesList);

    if ((getCookie("cookieAccepted") === "" && getCookie("analyticsAccepted") === "")) {
        setCookie("cookiesAccepted", false, 30000);
        setCookie("analyticsAccepted", false, 30000);
    }

    const setCookiesList = () =>{
        let arr1 = [], arr2 = [];
        cookiesAnalytics.map((cookie) =>{
            arr1.push(cookie);
        });
        cookiesMandatory.map((cookie) =>{
            arr2.push(cookie);
        });
        let cookiesList = [arr1, arr2]
        return cookiesList;
    }

    useEffect(() => {
        if(getCookie("cookiesAccepted") === "true"){
            setDisplay(true);
        }
    }, [display]);

    return <React.Fragment>
        {display == false && (<CookiesWindow cookiesList={setCookiesList()} setDisplay={setDisplay}/>)}
    </React.Fragment>
}

export default CookieManager;