import Row from "../../layouts/Row";
import React, {useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useIntl } from "react-intl";
import Paragraph from "../../components/Texts/Paragraph";
import CookiesButton from "../../components/Buttons/CookiesButton";
import Column from "../../layouts/Column";
import * as actions from '../../../modules/cookies/actions';
import * as selectors from '../../../modules/cookies/selectors';
import { manageCookies } from "../../../utils/cookiesUtils";
import CookiesModal from "./CookiesModal"

import './cookies-window.less';

const CookiesWindow = (props) =>{
    const intl = useIntl();
    const dispatch = useDispatch();
    const {cookiesList, setDisplay} = props;
    const [cookiesConsented, setCookiesConsented] = useState({mandatory: true, analytics: true});
    
    const cookieConsent = useSelector(selectors.consentCookies);

    const displayModal = (e) => {
        e.preventDefault();
        dispatch(actions.manageCookieConsent({modal: true}));
    };

    const closeModal = () => {
        dispatch(actions.manageCookieConsent({modal: false}));
        setCookiesConsented({mandatory: true, analytics: false});
    }

    const callManage = () => {
        manageCookies(cookiesConsented);
        setDisplay(true);
    }

    return <div className="cookies-dark-background-layout">
        <Row nopadding className={`cookies-window`}>
            <Paragraph className="font18" padding={"none"}>
                {intl.formatMessage({ id: 'cookies.window.text1' })}
                <a className="link-black-color" href="/politica-de-cookies">{intl.formatMessage({ id: 'cookies.window.link.politic' })}</a>
            </Paragraph>
            <Paragraph className="font18">
                {intl.formatMessage({ id: 'cookies.window.text2' })}
                <a href="/" onClick={displayModal}>{intl.formatMessage({ id: 'cookies.window.link.config' })}</a>
            </Paragraph>
            <Column className="align-center-cookies-button">
                <CookiesButton className="cookiesButton" onClick={() => callManage()}>{intl.formatMessage({id: 'cookies.window.button.text'})}</CookiesButton>
            </Column>
            <CookiesModal content={cookiesList} show={cookieConsent.modal} closeModal={closeModal} cookiesConsented={cookiesConsented} setCookiesConsented={setCookiesConsented} callManage={callManage} />
        </Row>
    </div>
}

export default CookiesWindow;