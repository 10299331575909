import React from 'react';
import PropTypes from 'prop-types';

import './title.less';

const Title = (props) => {
  const {depth, uppercase, className, children} = props;

  switch(depth){
    case 1: return <h1 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h1>;
    case 2: return <h2 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h2>;
    case 3: return <h3 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h3>;
    case 4: return <h4 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h4>;
    case 5: return <h5 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h5>;
    case 6: return <h6 className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</h6>;
    default: return <p className={`title ${className} ${uppercase ? 'uppercase' : ''}`}>{children}</p>;
  }
}

Title.defaultProps = {
  depth: 0,
  uppercase: false
};
  
Title.propTypes = {
  depth: PropTypes.number,
  uppercase: PropTypes.bool
};

export default Title;
