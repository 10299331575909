import React from 'react';
import PropTypes from 'prop-types';

import './row.less';

const Row = (props) => {
  const { children, mode, childRef, mvcustom, fullscreen, nomargin, nogap, nopadding, isSection, rotate, isMenu, collage, isFooter, className, overlapped } = props;

  if (isSection && !isMenu) {
    return <section
      ref={childRef}
      className={`row 
        ${className}
        ${mode} 
        ${mvcustom} 
        ${fullscreen ? "full" : ""} 
        ${nomargin ? "no-margin" : ""}
        ${nogap ? "no-gap" : ""}
        ${nopadding ? "no-padding" : ""}
        ${rotate ? "rotate-mobile" : ""}
        ${collage ? "collage" : ""}
        ${isFooter ? "footer" : ""}
        ${overlapped ? "overlapped" : ""}`
      }>
      {children}
    </section>;
  }

  return <div
    ref={childRef}
    className={`row 
      ${className}
      ${mode} 
      ${mvcustom} 
      ${fullscreen ? "full" : ""} 
      ${nomargin ? "no-margin" : ""}
      ${nogap ? "no-gap" : ""}
      ${nopadding ? "no-padding" : ""}
      ${rotate ? "rotate-mobile" : ""}
      ${isMenu ? "menu" : ""}
      ${collage ? "collage" : ""}
      ${isFooter ? "footer" : ""}`
    }>
    {children}
  </div>;
}

Row.defaultProps = {
  mode: "bright",
  fullscreen: false,
  mvcustom: "",
  nomargin: false,
  nogap: false,
  nopadding: false,
  isSection: true,
  rotate: false,
  isMenu: false,
  collage: false,
  isFooter: false,
  overlapped: false,
  className: ""
};

Row.propTypes = {
  mode: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  mvcustom: PropTypes.string,
  nomargin: PropTypes.bool,
  nogap: PropTypes.bool,
  isSection: PropTypes.bool,
  isMenu: PropTypes.bool,
  rotate: PropTypes.bool,
  childRef: PropTypes.any,
  collage: PropTypes.bool,
  isFooter: PropTypes.bool,
  overlapped: PropTypes.bool
};

export default Row;
