import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'

const Header = lazy(() => import('./Header'));
const Body = lazy(() => import('./Body'));
const Footer = lazy(() => import('./Footer'));
import CookieManager from '../../../design/blocks/cookies/CookiesManager';
import WhatsappLogo from '../../../design/blocks/whatsapp/WhatsappLogo';

import '../styles/global.less';

const App = () => {
  const pageTitle = document.title;
  const newMessage = "¡Somos tu mejor opción!";

  document.addEventListener('visibilitychange', function (e) {
    const isPageActive = !document.hidden;

    if (!isPageActive) {
      document.title = newMessage;
    } else {
      document.title = pageTitle;
    }
  });

  useEffect(() => {
    loadReCaptcha("6LcREXImAAAAAHLVfEcLVqEZHHEHH_Rq4BGvR6zJ", ()=>{});
  }, []);

  return (
    <Suspense>
      <BrowserRouter>
        <Header />
        <Body />
        <Footer />
      </BrowserRouter>
      <CookieManager />
      <WhatsappLogo />
    </Suspense>
  );
};

export default App;
