import React from 'react';

import './table.less';

const TableContainer = (props) => {
  const {children, className} = props;
  
  return <table className={`table-container ${className}`}>{children}</table>;
}

export default TableContainer;
