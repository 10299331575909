import React from 'react';
import PropTypes from 'prop-types';

import './column.less';

const Column = (props) => {
  const {children, className, size} = props;
  
  return <div className={`column --${size} ${className}`}>{children}</div>;
}
  
Column.propTypes = {
  size: PropTypes.number
};

Column.defaultProps = {
  className: ""
};

export default Column;
