import React from 'react';

const TableBody = (props) => {
  const {cookies, className, intl} = props;
  
  if (cookies) {
    return(
      <tbody className={className}>
          {cookies.map((cookie, index) => (
              <tr key={index}>
                  <td>{cookie.name}</td>
                  <td>{intl.formatMessage({ id: cookie.duration })}</td>
                  <td>{intl.formatMessage({ id: cookie.description })}</td>
              </tr>
          ))}
      </tbody>
    )
  }
}

export default TableBody;
