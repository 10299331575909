import {combineReducers} from 'redux';

import * as actionTypes from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  menu: [
    {
      name: "Proyectos",
      url: "/proyectos"
    },
    {
      name: "Ecommerce",
      url: "/ecommerce"
    },
    {
      name: "Web",
      url: "/web-corporativa"
    },
    {
      name: "Marketing",
      url: "/marketing",
      items: [
        {
          name: "SEO",
          url: "/seo"
        },
        {
          name: "GMB",
          url: "/gmb"
        },
        {
          name: "Google Ads",
          url: "/google-ads"
        },
        {
          name: "Social Ads",
          url: "/social-ads"
        },
        {
          name: "Analítica",
          url: "/analitica"
        },
        {
          name: "Mail Marketing",
          url: "/mail-marketing"
        }
      ]
    },
    {
      name: "Consultoría",
      url: "",
      items: [
        {
          name: "Consultoría Ecommerce",
          url: "/consultoria-ecommerce"
        },
        {
          name: "Consultoría Marketing",
          url: "/consultoria-marketing"
        },
      ]
    },
    {
      name: "Equipo",
      url: "/equipo"
    },
    {
      name: "Blog",
      url: "https://pululart.es/blog",
      external: true
    },
    {
      name: "Contacto",
      url: "/contacto",
      className: "final-item"
    },
    {
      name: "Trabaja con nosotros",
      url: "/trabaja-con-nosotros",
      className: "work-item"
    }
  ],
  featuredMenu: [
    {
      name: "Ecommerce",
      link: "/ecommerce"
    },
    {
      name: "Marketing",
      link: "/marketing"
    },
    {
      name: "Proyectos",
      link: "/proyectos"
    },
    {
      name: "Contacto",
      link: "/contacto"
    }
  ],
  servicesList: [
    'No consigues llegar a tu público objetivo.', 
    'Quieres publicitarte, pero no tienes un presupuesto muy elevado.', 
    'Eres capaz de llegar a tu público, pero no consigues que conviertan.', 
    'Se acercan fechas señaladas y quieres aprovecharlas para vender más.', 
    'Buscas reforzar tu imagen de marca y promocionarla.', 
    'Sabes cuáles son tus productos estrella y quieres explotarlos.'
  ],
  footerDynamicImagesFull : [
      {
      route: "/images/footer/magento.webp",
      name:'Magento image'
      },
      {
      route: "/images/footer/footerFacebook.webp",
      name:'Facebook image'
      },
      {
      route: "/images/footer/footerInstagram.webp",
      name:'Instagram image'
      },
      {
      route: "/images/footer/analytics.webp",
      name:'Analytics image'
      },
      {
      route: "/images/footer/footerGoogleAds.webp",
      name:'GoogleAds image'
      },
      {
      route: "/images/footer/footerFigma.webp",
      name:'Figma image'
      },
      {
      route: "/images/footer/react.webp",
      name:'React image'
      },
      {
      route: "/images/footer/footerWooComerce.webp",
      name:'WooCommerce image'
      },
      {
      route: "/images/footer/footerLookerStudio.webp",
      name:'LookerStudio image'
      },
      {
      route: "/images/footer/wordpress.webp",
      name:'Wordpress image'
      },
      {
      route: "/images/footer/footerMeta.webp",
      name:'Meta image'
      },
      {
      route: "/images/footer/footerPHP.webp",
      name:'PHP image'
      },
      {
      route: "/images/footer/mysql.webp",
      name:'MySql image'
      },
      {
      route: "/images/footer/plesk.webp",
      name:'Plesk image'
      },
      {
      route: "/images/footer/angular.webp",
      name:'Angular image'
      },
      {
      route: "/images/footer/gmb.webp",
      name:'Google My Business image'
      },
      {
      route: "/images/footer/shop-google.webp",
      name:'Shop with Google image'
      },
      {
      route: "/images/footer/odoo.webp",
      name:'Odoo image'
      }
  ],

  tokenRecaptcha: null
};

const menu = (state = initialState.menu, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const featuredMenu = (state = initialState.featuredMenu, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const error = (state = initialState.error, action) => {
  switch (action.type) {
    case actionTypes.ERROR:
      return action.error;
    default:
      return state;
  }
};

const loading = (state = initialState.loading, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return true;
    case actionTypes.LOADED:
      return false;
    case actionTypes.ERROR:
      return false;
    default:
      return state;
  }
};

const servicesList = (state = initialState.servicesList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const footerDynamicImagesFull = (state = initialState.footerDynamicImagesFull, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const tokenRecaptcha = (state = initialState.tokenRecaptcha, action) => {
    switch(action.type) {
      case actionTypes.GET_TOKEN_RECAPTCHA:
        return action.tokenRecaptcha;
      default: 
        return state;
    }
}

const reducer = combineReducers({
  error,
  loading,
  menu,
  featuredMenu,
  servicesList,
  footerDynamicImagesFull,
  tokenRecaptcha
});

export default reducer;
