import React from 'react';

import './row-auxiliar-layout.less';

const RowAuxiliarLayout = (props) => {
  const {children, childRef, onClick} = props;

    return <section 
      ref={childRef}
      className="background-opacity-layout">
        {children}
    </section>;
}

export default RowAuxiliarLayout;