const getModuleState = (state) => state.app;

export const getError = (state) => getModuleState(state).error;
export const isLoading = (state) => getModuleState(state).loading;
export const getMenuItems = (state) => getModuleState(state).menu;
export const getFeaturedMenuItems = (state) => getModuleState(state).featuredMenu;
export const getServicesList = (state) => getModuleState(state).servicesList;
export const getFooterDynamicImagesFull = (state) => getModuleState(state).footerDynamicImagesFull;
export const getTokenRecaptcha = (state) => getModuleState(state).tokenRecaptcha;

