import React from "react";

import "./whatsapp-logo.less";


const WhatsappLogo = () => {

    return (
        <a className = "whatsappLogoContainer" href="https://wa.me/34672643898?text=%C2%A1Hola%2C%20Pululart%21%20Tengo%20un%20nuevo%20proyecto...%20%3A%29" rel="noreferrer noopener">
            <span className = "whatsappNotification">1</span>

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24" xmlSpace="preserve">
                <g>
                    <path fill="#FFF" d="M11.42,0.064C5.109,0.365,0.128,5.622,0.147,11.94c0.006,1.924,0.47,3.74,1.29,5.346l-1.258,6.108
                        c-0.068,0.331,0.229,0.62,0.558,0.542l5.985-1.418c1.538,0.766,3.266,1.209,5.093,1.236c6.451,0.099,11.831-5.024,12.032-11.472
                        C24.063,5.37,18.357-0.266,11.42,0.064z M18.563,18.466C16.81,20.22,14.479,21.185,12,21.185c-1.452,0-2.841-0.326-4.131-0.968
                        l-0.833-0.416l-3.669,0.87l0.772-3.75l-0.41-0.804c-0.669-1.312-1.009-2.729-1.009-4.213c0-2.479,0.965-4.81,2.718-6.563
                        C7.175,3.604,9.543,2.623,12,2.623c2.479,0,4.81,0.966,6.563,2.718c1.753,1.753,2.719,4.084,2.719,6.563
                        C21.281,14.359,20.3,16.729,18.563,18.466z"></path>
                    <path fill="#FFF" d="M17.753,14.474l-2.296-0.659c-0.303-0.086-0.628,0-0.847,0.224l-0.562,0.572
                        c-0.237,0.241-0.597,0.318-0.91,0.191c-1.086-0.439-3.371-2.47-3.954-3.486c-0.168-0.293-0.14-0.66,0.066-0.927l0.49-0.634
                        c0.192-0.248,0.233-0.582,0.106-0.869L8.881,6.7c-0.231-0.523-0.9-0.675-1.337-0.306C6.903,6.936,6.143,7.759,6.05,8.672
                        c-0.163,1.608,0.527,3.636,3.136,6.07c3.013,2.813,5.427,3.185,6.999,2.805c0.892-0.217,1.604-1.081,2.054-1.791
                        C18.544,15.274,18.303,14.632,17.753,14.474z"></path>
                </g>
            </svg>
        </a>
    )
}


export default WhatsappLogo;